<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="5" md="5">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                @input="filterAppUser" />
            </div>
          </b-col>
          <b-col cols="7" md="7">
            <div class="d-flex justify-content-end align-items-center gap-1">
              <div style="min-width: 200px; margin-top: -3px;">
                <v-select
                  v-model="searchCountry"
                  :clearable="true"
                  label="name"
                  :options="get_countries"
                  :reduce="option => option.code"
                  @input="filterByCountryUsers(searchCountry)"
                  placeholder="Select Country"
                />
              </div>
              <div class="v-date-range-picker clear" style="width: 220px">
                <date-range-picker
                  ref="picker"
                  :opens="'left'"
                  :locale-data="{
                    direction: 'ltr',
                    format: 'yyyy-mm-dd',
                    separator: ' - ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    monthNames: [
                      'Jan',
                      'Feb',
                      'Mar',
                      'Apr',
                      'May',
                      'Jun',
                      'Jul',
                      'Aug',
                      'Sep',
                      'Oct',
                      'Nov',
                      'Dec',
                    ],
                    firstDay: 0,
                  }"
                  :timePicker="false"
                  :timePicker24Hour="true"
                  :showWeekNumbers="false"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="dateRange"
                  @update="filterByDateUsers"
                  :linkedCalendars="true">
                </date-range-picker>
                <template v-if="dateRange.startDate && dateRange.endDate">
                  <span class="clear-icon" @click="clearDateRange" style="margin-bottom: 16px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg>
                  </span>
                </template>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay :show="show" rounded="sm">
        <b-card no-body>
          <b-table-simple hover caption-top responsive class="rounded-bottom mb-0" :key="sidebarkey">
            <b-thead head-variant="light">
              <b-tr>
                <b-th>#</b-th>
                <b-th>Registration Date.</b-th>
                <b-th>Install Referrer</b-th>
                <b-th>Signup with</b-th>
                <!-- <b-th>Source device</b-th>
                <b-th>Country</b-th> -->
                <!-- <b-th>Current Plan</b-th> -->
                <b-th>Profile Id</b-th>
                <b-th>Profile Name</b-th>
                <b-th>Current Plan</b-th>
                <b-th v-if="removeButtons == true">Admin Access</b-th>
                <b-th>Gems</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="(users_data, index) in table_data.data">
                <b-tr :key="'row-' + index">
                  <b-th :rowspan="users_data.profile.length > 0
                    ? users_data.profile.length + 1
                    : 0
                    ">
                    <div class="date-details-container">
                      {{ (index + 1) + (currentPage - 1) * per_page }}
                    </div>
                  </b-th>

                  <b-th :rowspan="users_data.profile.length > 0
                    ? users_data.profile.length + 1
                    : 0
                    ">
                    <div class="date-details-container">
                      <!-- <div>{{ users_data.createdAt }}</div> -->
                      {{ Helpers.formatDateTime(users_data.createdAt) }}
                      <div>{{ users_data.timeZone }}</div>
                      <b-link @click="showUserDetails(users_data)" class="small-details-link">Show Details</b-link>
                    </div>
                  </b-th>

                  <b-th :rowspan="users_data.profile.length > 0
                    ? users_data.profile.length + 1
                    : 0
                    ">
                    <div class="date-details-container">
                      <b-link @click="showInstallReferrerSource(users_data)">
                        {{ users_data.installReferrerSource }}
                      </b-link>
                    </div>
                  </b-th>

                  <b-th :rowspan="users_data.profile.length > 0
                    ? users_data.profile.length + 1
                    : 0
                    ">
                    <div class="d-flex align-items-center">
                      <span v-if="users_data.userBy">
                        <img v-if="users_data.userBy == 'sign-up-with-gmail'" style="width: 20px;" src="/googleIcon.png"
                          alt="google-icon">
                        <img v-if="users_data.userBy == 'sign-up-with-apple'" style="width: 20px;" src="/appleIcon.png"
                          alt="google-icon">
                        <feather-icon v-if="users_data.userBy == 'create-account'" size="20" style="color: #6FBFFF"
                          icon="KeyIcon" />
                      </span>
                      <span v-if="users_data.devicePlatform" style="margin-left: 8px;">
                        <img v-if="users_data.devicePlatform == 'ios'" style="width: 30px;" src="/ios.png"
                          alt="ios-icon">
                        <img v-if="users_data.devicePlatform == 'android'" style="width: 30px;"
                          src="/devicon_android.png" alt="android-icon">
                      </span>
                      <span v-if="users_data.source_country" style="margin-left: 4px;">
                        <flag-component v-if="users_data.timeZone == 'Asia/Karachi'" :countryCode="'PK'" />
                        <flag-component v-else :countryCode="users_data.source_country" />
                      </span>
                    </div>
                  </b-th>
                  <!-- <b-th :rowspan="users_data.profile.length > 0
                    ? users_data.profile.length + 1
                    : 0
                    ">
                    <span v-if="users_data.current_plan">
                      {{ users_data.current_plan.name }}
                    </span>
                    <span v-else>
                      Free
                    </span>
                    <span @click="editUserPlanBtn(users_data)">
                      <feather-icon icon="EditIcon" />
                    </span>
                  </b-th> -->
                </b-tr>
                <template v-for="(profile, ind) in users_data.profile">
                  <b-tr :key="'row-' + index + '-col-' + ind">
                    <b-th>
                      
                        {{ profile.serialNumber }}
                    </b-th>
                    <b-th class="d-flex">
                      <b-link :to="{
                        path: '/user-report/' + profile.key,
                      }">
                        {{ profile.name }} 
                    </b-link>
                    </b-th>
                    <b-th >
                      <span v-if="profile.current_plan">
                      {{ profile.current_plan.name }}
                    </span>
                    <span v-else>
                      Free
                    </span>
                    <span @click="editUserPlanBtn(profile)">
                      <feather-icon icon="EditIcon" />
                    </span>
                    </b-th>
                    <b-th v-if="removeButtons == true">
                      <b-form-checkbox v-model="profile.adminAccess" v-on:change="confirmStatusChange(profile)" switch
                        size="lg"></b-form-checkbox>
                    </b-th>
                    <b-td>
                      <appUsersGemsHistory
                        :profile="profile"
                      ></appUsersGemsHistory>
                    </b-td>

                  </b-tr>
                </template>
              </template>
            </b-tbody>
          </b-table-simple>
          <div class="mx-2 mt-1">
            <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
              <div class="dataTables_info mt-1">Showing {{ table_data.from }} to {{ table_data.to }} of {{
                table_data.total }}
                entries
              </div>
              <b-pagination v-model="currentPage" :total-rows="table_data.total" :limit=7
                :per-page="per_page" @change="getTableData" class="mt-1 mb-0">
              </b-pagination>

            </div>
          </div>
        </b-card>
      </b-overlay>
      <b-modal centered v-model="showUserDetailsModal" title="User Details">
        <div>
          <p>Email/Username: {{ selectedProfileEmail }}</p>
          <p>ID: {{ selectedId }}</p>
        </div>
      </b-modal>
      <b-modal centered size="lg" v-model="showInstallReferrerSourceModal" title="Install Referrer Detail" hide-footer>
        <div>
          <div v-if="InstallReferrerSourceData.installReferrer">
            <table class="table table-bordered">
                <tbody>
                    <tr v-for="(item, key) in Helpers.jsonToArray(InstallReferrerSourceData.installReferrer)" :key="key">
                        <th>{{ item.key }}</th>
                        <td>
                          <div style="word-break: break-all;">
                            {{ item.value }}
                          </div>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
      </b-modal>
      <b-modal title="Provide Description" centered v-model="showdiscriptionModal" @cancel.prevent="cancelModal"
        @ok.prevent="submitDescription" @close.prevent="cancelModal">
        <b-form-group label="Please enter a description (at least 50 characters):">
          <b-form-textarea v-model="description" rows="6"></b-form-textarea>
        </b-form-group>
        <div class="alert alert-danger" v-if="descriptionError">
          Please enter at least 50 characters.
        </div>
        <div>
          <span>{{ description.length }}</span> / 50 characters
        </div>
      </b-modal>
    </b-card>
    <UserCurrentPlan :manage="true" :get_plans="get_plans" :get_billing_cycles="get_billing_cycles"
      @emitPlanUpdated="getTableData" :selected_user="selected_user"></UserCurrentPlan>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BLink,
  BFormCheckbox,
  BTableSimple,
  BFormInput,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BOverlay,
  BTfoot,
  BFormTextarea,
  BPagination, 
} from "bootstrap-vue";
import axios from "axios";
import FlagComponent from "./components/FlagComponent.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserCurrentPlan from "./Models/UserCurrentPlan.vue";
import appUsersGemsHistory from "../views/Models/appUsersGemsHistory.vue";
import * as Helpers from "../Helpers";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from 'vue-select';

export default {
  components: {
    FlagComponent,
    BOverlay,
    BFormCheckbox,
    BCard,
    BRow,
    ToastificationContent,
    BCol,
    BTable,
    BLink,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BFormInput,
    BTd,
    BTbody,
    BTfoot,
    BFormTextarea,
    BPagination,
    UserCurrentPlan,
    appUsersGemsHistory,
    DateRangePicker,
    vSelect,
  },
  data() {
    return {
      table_data: [],
      searchQuery: "",
      searchCountry: "",
      show: false,
      removeButtons: "",
      table_: [],
      toastMsg: "",
      sidebarkey: 0,
      selectedProfileEmail: "",
      selectedId: "",
      showUserDetailsModal: false,
      showInstallReferrerSourceModal: false,
      notificationMessage: "",
      description: '',
      descriptionError: false,
      showdiscriptionModal: false,
      adminAccess: {},
      currentPage: 1,
      selected_user: {},
      get_plans: [],
      get_billing_cycles: [],
      get_countries: [],
      per_page:0,
      Helpers,
      InstallReferrerSourceData: {},
      dateRange: {},
    };
  },
  mounted() {
    var newPermission = JSON.parse(localStorage.getItem("permission"));
    for (let index = 0; index < newPermission.permission.length; index++) {
      if (newPermission.permission[index] == "manage_app_users") {
        this.removeButtons = true;
        break;
      } else {
        this.removeButtons = false;
      }
    }
  },
  created() {
    // this.dateRange.startDate = this.Helpers.getTodayDates().startDate;
    // this.dateRange.endDate = this.Helpers.getTodayDates().endDate;
    this.dateRange = {
      startDate: '',
      endDate: '',
    };
    this.getTableData();
  },
  methods: {
    confirmStatusChange(object) {
      this.showdiscriptionModal = true;
      this.adminAccess = object;
    },
    cancelModal() {
      this.showdiscriptionModal = false;
      this.adminAccess.adminAccess = !this.adminAccess.adminAccess;
      this.descriptionError = false;
      this.description = '';
    },
    submitDescription() {
      if (this.description.length < 50) {
        this.descriptionError = true;
      } else {
        this.descriptionError = false;
        const payload = {
          ...this.adminAccess,
          description: this.description
        };
        this.statusChangeAdminAccess(payload);
        this.showdiscriptionModal = false;
        this.description = ''
      }
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.notificationMessage,
          variant,
        },
      });
    },
    showUserDetails(profile) {
      let id = typeof profile._id === 'string' ? profile._id : profile._id.$oid;

      axios.post(process.env.VUE_APP_API_URL + "/app-user/email/log", {
        userId: id
      }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("_t"),
        },
      })
        .then(response => {
          this.selectedProfileEmail = response.data.email;
          this.selectedId = response.data.Id
          this.showUserDetailsModal = true;
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            this.notificationMessage = 'Request timed out. Please try again later.';
          } else if (error.response) {
            this.notificationMessage = error.response.data.message;
          } else {
            this.notificationMessage = 'An error occurred. Please try again later.';
          }
          console.log('error=', error.response)
          this.showToasted("danger");
          this.show = false;
        });
    },
    showInstallReferrerSource(data) {
      this.showInstallReferrerSourceModal = true;
      this.InstallReferrerSourceData = data;
    },
    formatDate(dateObj) {
      if (dateObj && dateObj['$date'] && dateObj['$date']['$numberLong']) {
        const timestamp = parseInt(dateObj['$date']['$numberLong']);
        const date = new Date(timestamp);
        const options = {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZoneName: 'short' // Include time zone name
        };
        return date.toLocaleString('en-GB', options); // Adjust locale as needed
      } else {
        return '-';
      }
    },
    filterAppUser() {
      this.show = true;
      setTimeout(() => {
        this.getTableData(1, this.searchQuery);
        this.show = false;
      }, 1000);
    },
    forcRender() {
      this.sidebarkey += 1;
    },
    statusChangeAdminAccess(object) {
      this.show = true;
      axios
        .post(process.env.VUE_APP_API_URL + "/app-user/change/status", object, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          if (get_response.data.status) {
            this.toastMsg = get_response.data.message;
            this.showToast("success");
            this.show = false;
          } else {
            this.toastMsg = "There are some problem try again";
            this.showToast("danger");
            this.show = false;
          }
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    showToast(variant) {
      // alert(0);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.toastMsg,
          variant,
        },
      });
    },
    clearDateRange() {
      this.dateRange = {
        startDate: null,
        endDate: null,
      };
      this.getTableData();
    },
    filterByCountryUsers(country){
      console.log('filterByDateUsers country=', country);
      this.getTableData(1);
    },
    filterByDateUsers(date){
      console.log('filterByDateUsers date=', date);
      this.getTableData(1);
    },
    getTableData: function (page = 1) {
      var url = process.env.VUE_APP_API_URL + `/app-user/data/all?page=${page}`;
      const payload = {}
      payload.search = this.searchQuery;
      payload.country = this.searchCountry;
      payload.startDate = this.dateRange.startDate? Helpers.changeDateFormat(this.dateRange.startDate):null;
      payload.endDate = this.dateRange.endDate? Helpers.changeDateFormat(this.dateRange.endDate):null;
      this.show = true;
      axios
        .post(url, payload, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.get_plans = get_response.data.billing_plans;
          this.get_billing_cycles = get_response.data.billing_cycles;
          this.get_countries = get_response.data.countries;
          let response = get_response.data.accounts;
          for (let index = 0; index < response.length; index++) {
            for (let k = 0; k < response[index].profile.length; k++) {
              if (!response[index].profile[k]["adminAccess"]) {
                response[index].profile[k]["adminAccess"] = false;
              }
            }
          }
          this.table_ = response;
          this.table_data = response;
          this.show = false;
          this.per_page = response.per_page
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    editUserPlanBtn(userData) {
      this.selected_user = userData;
      this.$bvModal.show("user-current-plan-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.date-details-container {
  display: flex;
  flex-direction: column;
}

.small-details-link {
  font-size: 10px;
}
</style>